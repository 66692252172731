import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
//import Headings from "../components/headings"
import Hero from "../components/hero"
import ContactForm from "../components/contact-form"
import Headings from "../components/headings"

import HomeIcon from "../icons/home-icon-silhouette.svg"
//import EmailIcon from "../icons/emails-icon.svg"
//import PhoneIcon from "../icons/phone-icon.svg"
//import DonationsIcon from "../icons/donation-icon.svg"
import CharityIcon from "../icons/charity-icon-v1.svg"
import DonationsIconV2 from "../icons/donation-icon-v2.svg"
import CtaSlider from "../components/cta-slider"
import { Helmet } from "react-helmet"

const ContactUsPage = pageContext => {
  //console.log("Contact us - pageContext: ", pageContext)

  const { strapiContactUs } = useStaticQuery(graphql`
    query {
      strapiContactUs {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
        }
        callToAction {
          ...StrapiCallToAction
        }
      }
    }
  `)
  const { hero, seo, callToAction } = strapiContactUs

  const structuredData = seo.structuredData

  const callToActionSections = callToAction.call_to_action_sections

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <section className="uk-section-default">
        <div
          className="uk-section uk-section-default "
          uk-height-viewport="expand: true"
        >
          <div className="uk-container ">
            <div className="uk-grid-large uk-margin" uk-grid="true">
              <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
                <div className="uk-panel">
                  <Headings
                    title="Get in touch"
                    description="If you need more information about any of our services, do not hesitate to contact us, using our form, or feel free to email us, by clicking on your icon of interest below, and we will get back to you"
                  />

                  <div className="uk-width-xlarge">
                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin uk-margin-medium-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="mailto:info@stsp-srilanka.org?subject=STSP Sri Lanka - Information queries">
                          <HomeIcon
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column uk-margin-small-right ">
                        <div
                          className="uk-text-bold uk-margin-small-right"
                          style={{ color: "#617F7F" }}
                        >
                          For information or support
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-medium-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="mailto:donations@donations@stsp-srilanka.org?subject=STSP Sri Lanka - Donations queries">
                          <DonationsIconV2
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column">
                        <div
                          className="uk-text-bold"
                          style={{ color: "#617F7F" }}
                        >
                          For donations queries
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-medium-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="mailto:sponsorship@stsp-srilanka.org?subject=STSP Sri Lanka - Sponsorship queries">
                          <CharityIcon
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column">
                        <div
                          className="uk-text-bold"
                          style={{ color: "#617F7F" }}
                        >
                          For sponsorship queries
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
                <div className="uk-panel">
                  <div className="uk-margin">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Headings
          title="Our Location"
          description="Pease see STSP Ltd Registered Office below."
        />

        <div className="uk-container uk-margin-medium-bottom">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.7949908197907!2d-0.09892102304165791!3d51.370065420726405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760735a8293963%3A0x11a9fa8448ec4776!2sBusiness%20Centre%2C%2073%20Park%20Ln%2C%20Croydon%20CR0%201JG!5e0!3m2!1sen!2suk!4v1721502060086!5m2!1sen!2suk"
            width="800"
            height="600"
            allowFullScreen=""
            loading="lazy"
            title="map"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <CtaSlider
        title={callToAction.title}
        description={callToAction.description}
        callToActionSections={callToActionSections}
        bg={callToAction.bg}
        image={callToAction.image}
        paypalButton={callToAction.paypalButton}
      />
    </>
  )
}

export default ContactUsPage
